import './Header.scss'

import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom'
import { Logout, Settings } from '@carbon/icons-react'
import { Popover, PopoverContent } from '@carbon/react'

import { Logo } from '../Logo/Logo'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { Username } from '../Username/Username'
import { signOut } from 'aws-amplify/auth'
import { useSessionStorage } from 'hooks/useSessionStorage'
import { useState } from 'react'

type HeaderProps = {
  displayTitle?: boolean
  displayTabs?: boolean
}

export function Header({ displayTitle, displayTabs }: HeaderProps) {
  const { pathname } = useLocation()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [currentChatId] = useSessionStorage(Params.CURRENT_CHAT, '')
  const [currentSearch] = useSessionStorage(Params.CURRENT_SEARCH, '')
  // const currentSearch = sessionStorage.getItem(Params.CURRENT_SEARCH)
  const projectSearchLink = currentSearch
    ? `${Pages.PROJECT_SEARCH}?${Params.SEARCH}=${currentSearch}`
    : Pages.PROJECT_FINDER
  const navigate = useNavigate()

  const handleSignOut = async () => {
    try {
      await signOut()
    } catch (error) {
    } finally {
      navigate(Pages.LOGIN)
    }
  }

  return (
    <div className="header-container">
      <div style={{ height: '100%', display: 'flex', gap: 'calc(2rem + 1px)' }}>
        {displayTitle && <Logo />}
        {displayTabs && (
          <div className="tabs">
            <NavLink
              to={`${Pages.CHAT}${
                currentChatId ? `?chat=${currentChatId}` : ''
              }`}
              className="nav-tab"
            >
              Chat
            </NavLink>
            <NavLink
              to={projectSearchLink}
              className={`nav-tab ${
                pathname.includes(Pages.PROJECT_FINDER) ? 'active' : ''
              }`}
            >
              Project finder
            </NavLink>
          </div>
        )}
      </div>
      <div className="action-bar">
        <Popover
          open={settingsOpen}
          caret={false}
          onRequestClose={() => setSettingsOpen(false)}
        >
          <span className="logout-btn" onClick={() => setSettingsOpen(true)}>
            <Username />
          </span>

          <PopoverContent>
            <div>
              <Link to={Pages.EXPLORE}>
                <p className="popover-line">
                  <Settings size="24" /> Help & Support
                </p>
              </Link>
              <p className="popover-line" onClick={handleSignOut}>
                <Logout size="24" />
                Log out
              </p>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  )
}
