import { Button, Column, Form, TextInput } from 'carbon-components-react'
import { confirmSignUp, resendSignUpCode } from 'aws-amplify/auth'
import { useEffect, useState } from 'react'

import { ErrorMessages } from 'constants/errorMessages'
import { InlineBanner } from 'components/inline-banner/InlineBanner'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { useNavigate } from 'react-router-dom'

export type ConfirmEmailData = {
  code: string
}

export function ConfirmEmail() {
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isResendDisabled, setIsResetDisabled] = useState(false)
  const emailToConfirm = sessionStorage.getItem(
    Params.EMAIL_TO_CONFIRM
  ) as string
  const navigate = useNavigate()

  useEffect(() => {
    if (!emailToConfirm || emailToConfirm === null) {
      navigate(Pages.LOGIN)
    }
  })

  const confirmEmail = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const form = e.target
    const data: ConfirmEmailData = Object.fromEntries(
      new FormData(form).entries()
    ) as ConfirmEmailData

    try {
      const { isSignUpComplete } = await confirmSignUp({
        username: emailToConfirm,
        confirmationCode: data.code,
      })
      if (isSignUpComplete) {
        sessionStorage.removeItem(Params.EMAIL_TO_CONFIRM)
        navigate(Pages.EMAIL_CONFIRMED)
      } else {
        setIsLoading(false)
        setHasError(true)
        setErrorMessage(ErrorMessages.GENERIC_ERROR)
      }
    } catch (error: any) {
      setIsLoading(false)
      setHasError(true)
      setErrorMessage(error.message)
    }
  }

  const resendCode = async () => {
    try {
      await resendSignUpCode({ username: emailToConfirm as string })
      setIsResetDisabled(true)
      setTimeout(() => setIsResetDisabled(false), 20000)
    } catch (e: any) {}
  }

  return (
    <LoginLayout>
      <Column
        lg={{ span: 6, offset: 1 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--p-05"
      >
        <InlineBanner
          isVisible={hasError}
          setIsVisible={setHasError}
          subtitle={errorMessage}
        />
        <h1 className="cds--type-semibold">Check your email</h1>
        <p>
          If you're an approved user, you should receive an email containing
          your verification code. Please enter the code below to activate your
          account.
        </p>
        <div className="divider w-100 cds--mb-07" />
        <Form aria-label="login form" onSubmit={confirmEmail}>
          <TextInput
            labelText="Verification code"
            placeholder="Verification code"
            id="code"
            name="code"
            className="w-30"
          />
          <LoadingButton
            type="submit"
            className="btn-primary cds--mt-06"
            isLoading={isLoading}
            value="Submit code"
          />
        </Form>
        <p>Didn't receive the email?</p>
        <Button
          className="cds--link-button cds--pl-0 cds--pr-0"
          kind="ghost"
          disabled={isResendDisabled}
          onClick={resendCode}
        >
          Resend
        </Button>
      </Column>
    </LoginLayout>
  )
}
