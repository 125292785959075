import { Column, Form, TextInput } from 'carbon-components-react'
import { useEffect, useState } from 'react'

import { InlineBanner } from 'components/inline-banner/InlineBanner'
import { LoadingButton } from 'components/loading-button/LoadingButton'
import { LoginLayout } from 'layouts/login-layout/LoginLayout'
import { Pages } from 'constants/pages'
import { Params } from 'constants/params'
import { confirmResetPassword } from 'aws-amplify/auth'
import { useNavigate } from 'react-router-dom'

export function NewPassword() {
  const [hasError, setHasError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const emailForReset = sessionStorage.getItem(Params.EMAIL_FOR_RESET) as string
  const navigate = useNavigate()

  useEffect(() => {
    if (!emailForReset || emailForReset === null) {
      navigate(Pages.FORGOTTEN_PASSWORD)
    }
  })

  const handleConfirmResetPassword = async (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    const form = e.target
    const formData = new FormData(form)
    const { code, password } = Object.fromEntries(formData.entries())
    try {
      await confirmResetPassword({
        confirmationCode: code as string,
        newPassword: password as string,
        username: emailForReset,
      })
      sessionStorage.removeItem(Params.EMAIL_FOR_RESET)
      navigate(Pages.PASSWORD_RESET)
    } catch (error: any) {
      setIsLoading(false)
      setHasError(true)
      setErrorMessage(error.message)
    }
  }

  return (
    <LoginLayout>
      <Column
        lg={{ span: 4, offset: 2 }}
        md={{ span: 4, offset: 1 }}
        sm={{ span: 4, offset: 0 }}
        className="cds--p-05"
      >
        <InlineBanner
          isVisible={hasError}
          setIsVisible={setHasError}
          subtitle={errorMessage}
        />
        <h1 className="cds--type-semibold">New Password</h1>
        <p>Enter the code sent to you via email and create a new password.</p>
        <Form aria-label="login form" onSubmit={handleConfirmResetPassword}>
          <TextInput
            labelText="Verification code"
            placeholder="Verification code"
            id="code"
            name="code"
            className="cds--mb-05"
          />
          <TextInput
            type="password"
            required
            pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}"
            labelText="New password"
            id="password"
            placeholder="password"
            name="password"
          />
          <LoadingButton
            type="submit"
            className="btn-primary"
            value="Reset password"
            isLoading={isLoading}
          />
        </Form>
      </Column>
    </LoginLayout>
  )
}
